/**
 * @fileoverview redux reducer for events
 */
import {createReducer} from "../../redux/utils/reducer";
import ActionTypes from "./event_action_types";

/**
 * Init state with all event names with type 'request'
 * @return {json} list with all events for state
 */
const initialState = () => ({
    eventKey: null,
    showBlockscreen: null,
    eventCreatedAt: null,
    blockedUntil: null,
    showTeaser: false
});

/**
 * Set redux state
 * @param {object} state
 * @param {object} data
 * @param {string} data.eventKey - event or state
 * @param {boolean} data.showBlockscreen
 * @param {string} data.blockedUntil
 * @return {object}
 */
const handleSetEvent = (state, {data}) => ({
    showTeaser: state.showTeaser,
    ...data
});

/**
 * Set blockedUntil
 * @param {object} state
 * @param {string} blockedUntil
 * @return {object}
 */
const handleSetBlockedUntil = (state, {blockedUntil}) => ({
    ...state,
    blockedUntil
});

/**
 * Set showTeaser
 * @param {object} state
 * @param {boolean} showTeaser
 * @return {object}
 */
const handleShowTeaser = (state, {showTeaser}) => ({
    ...state,
    showTeaser
});

/**
 * Mapper for handler and action types
 */
const handlers = {
    [ActionTypes.SET_EVENT]: handleSetEvent,
    [ActionTypes.SET_BLOCKED_UNTIL]: handleSetBlockedUntil,
    [ActionTypes.SET_SHOW_TEASER]: handleShowTeaser
};

export default createReducer(initialState(), handlers);
