// @ts-check
const STATUS = Object.freeze({
    /**
     * waiting
     */
    IDLE: "IDLE",
    /**
     * data invalidated, should be reloaded
     */
    INVALIDATED: "INVALIDATED",
    /**
     * action pending
     */
    PENDING: "PENDING",
    /**
     * action successful
     */
    RESOLVED: "RESOLVED",
    /**
     * action failed
     */
    REJECTED: "REJECTED"
});

const isIdle = (status) => status === STATUS.IDLE;
const isInvalid = (status) => status === STATUS.INVALIDATED;
const isPending = (status) => status === STATUS.PENDING;
const isResolved = (status) => status === STATUS.RESOLVED;
const isRejected = (status) => status === STATUS.REJECTED;

export default STATUS;
export {isIdle, isInvalid, isPending, isRejected, isResolved};
