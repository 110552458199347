// @ts-check
import {orchestratorPrefix} from "../../../config/api_config";
import axios from "../../middleware/axios.middelware";

const urlOrganization = `${orchestratorPrefix}/organization`;

/**
 * fetch print dates
 * @param {object} args
 * @param {string} args.organizationId
 * @return {Promise}
 */
function fetchPrintDates({organizationId}) {
    return axios.get(`${urlOrganization}/${organizationId}/print`);
}

/**
 * patch print dates
 * @param {object} args
 * @param {string} args.organizationId
 * @param {Array<{date: string, note: string}>} args.dates
 * @return {Promise}
 */
function patchPrintDates({organizationId, dates}) {
    return axios.patch(`${urlOrganization}/${organizationId}/print`, {dates});
}

export {fetchPrintDates, patchPrintDates};
