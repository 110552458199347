// @ts-check
import ActionTypes from "../actions/print_action_types";
import {createReducer} from "../utils/reducer";
import STATUS from "../utils/status";

const initialState = {
    loadPrintDatesStatus: STATUS.IDLE,
    savePrintDatesStatus: STATUS.IDLE,
    printDates: []
};

const handleLoadPrintDatesRequest = (state) => ({
    ...state,
    loadPrintDatesStatus: STATUS.PENDING
});

const handleLoadPrintDatesFailure = (state) => ({
    ...state,
    loadPrintDatesStatus: STATUS.REJECTED
});

const handleLoadPrintDatesSuccess = (state, {payload}) => ({
    ...state,
    loadPrintDatesStatus: STATUS.RESOLVED,
    printDates: payload
});
const handleSavePrintDatesRequest = (state) => ({
    ...state,
    savePrintDatesStatus: STATUS.PENDING
});

const handleSavePrintDatesFailure = (state) => ({
    ...state,
    savePrintDatesStatus: STATUS.REJECTED
});

const handleSavePrintDatesSuccess = (state) => ({
    ...state,
    savePrintDatesStatus: STATUS.RESOLVED
});

const handleResetStatusSavePrintDates = (state) => ({
    ...state,
    savePrintDatesStatus: STATUS.IDLE
});

const handlers = {
    [ActionTypes.LOAD_PRINT_DATES_REQUEST]: handleLoadPrintDatesRequest,
    [ActionTypes.LOAD_PRINT_DATES_SUCCESS]: handleLoadPrintDatesSuccess,
    [ActionTypes.LOAD_PRINT_DATES_FAILURE]: handleLoadPrintDatesFailure,
    [ActionTypes.SAVE_PRINT_DATES_REQUEST]: handleSavePrintDatesRequest,
    [ActionTypes.SAVE_PRINT_DATES_SUCCESS]: handleSavePrintDatesSuccess,
    [ActionTypes.SAVE_PRINT_DATES_FAILURE]: handleSavePrintDatesFailure,
    [ActionTypes.RESET_SAVE_PRINT_DATES_STATUS]: handleResetStatusSavePrintDates
};
export default createReducer(initialState, handlers);
