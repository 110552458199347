// @ts-check

import {createSelector} from "reselect";

/**
 * Selects the app slice from the  store
 *
 * @param {*} state
 * @return {AppState}
 */
const selectApp = (state) => state.app;

/**
 * Select the userObject key from the app slice from the store
 *
 * @param {*} state
 * @return {UserObjectStore}
 */
const selectCurrentUser = (state) => selectApp(state).userObject;

const selectCurrentOrganizationId = (state) => selectCurrentUser(state).organizationId;

const selectCurrentUserEmail = (state) => selectCurrentUser(state).email;

const selectCurrentUserPractitionerId = (state) => selectCurrentUser(state).practitionerId;

const selectCurrentTimezone = (state) => selectCurrentUser(state).timezone;

const selectCurrentUserFirstName = (state) => selectCurrentUser(state).firstName;

const selectCurrentUserLastName = (state) => selectCurrentUser(state).lastName;

const selectCurrentUserRoles = createSelector(selectCurrentUser, (currentUserState) => currentUserState.roles || []);

const selectCurrentUserRights = createSelector(selectCurrentUser, (currentUserState) => currentUserState.rights || []);

const selectAuthError = (state) => selectApp(state).authError;

const selectShowFullMainMenu = (state) => selectApp(state).showFullMainMenu;

const selectLanguage = (state) => selectApp(state).language;

const selectSlotViewDays = (state) => selectApp(state).slotViewDays;

const selectRefreshTrigger = (state) => selectApp(state).refreshTrigger;

const selectServiceVersion = (state) => selectApp(state).serviceVersion;

const selectLogoutStatus = (state) => selectApp(state).logoutStatus;

/**
 * Select user strategy (LDAP or local)
 *
 * @param {object} state
 * @return {UserStrategy}
 */
const selectUserStrategy = (state) => selectApp(state).userStrategy;

export {
    selectCurrentUser,
    selectCurrentOrganizationId,
    selectCurrentTimezone,
    selectCurrentUserEmail,
    selectCurrentUserPractitionerId,
    selectCurrentUserFirstName,
    selectCurrentUserLastName,
    selectAuthError,
    selectShowFullMainMenu,
    selectLanguage,
    selectSlotViewDays,
    selectCurrentUserRoles,
    selectCurrentUserRights,
    selectUserStrategy,
    selectRefreshTrigger,
    selectServiceVersion,
    selectLogoutStatus
};
