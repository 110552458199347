import {combineReducers} from "redux";

import accountReducer from "../../components/account/account_reducer";
import {disciplinesReducer} from "../../components/disciplines";
import {employeeAvailabilityReducer} from "../../components/employee_availability";
import employeesReducer from "../../components/employees/employees_reducer";
import {employeesAvailabilitiesReducer} from "../../components/employees_availabilities";
import feSettingsReducer from "../../components/fe_settings/fe_settings_reducer";
import {notificationsReducer} from "../../components/notifications";
import {opEditLayerReducer} from "../../components/op_edit_layer/op_edit_layer_reducer";
import privateDataReducer from "../../components/private_data/private_data_reducer";
import roomPlannerReducer from "../../components/room_planner/room_planner_reducer";
import roomPlannerDetailsReducer from "../../components/room_planner_details/room_planner_details_reducer";
import roomsReducer from "../../components/rooms/rooms_reducer";
import adminUserReducer from "../../pages/admin_user/admin_user_reducer";
import {availabilityPlannerReducer} from "../../pages/availability_planner";
import dayViewReducer from "../../pages/day_view/day_view_reducer";
import {opBacklogReducer} from "../../pages/op_backlog/op_backlog_reducer";
import opManagementReducer from "../../pages/op_management/op_management_reducer";
import {roomPlannerPageReducer} from "../../pages/room_planner";
import {surgeryAssignmentReducer} from "../../pages/surgery_assignment/surgery_assignment_reducer";
import timeslotTableReducer from "../../pages/timeslot_table/timeslot_table_reducer";
import timeslotsReducer from "../../pages/timeslots/timeslots_reducer";
import eventsReducer from "../events/event_reducer";
import appReducer from "./app_reducer";
import printReducer from "./print_reducer";

const reducers = {
    app: appReducer,
    dayView: dayViewReducer,
    opManagement: opManagementReducer,
    employees: employeesReducer,
    rooms: roomsReducer,
    feSettings: feSettingsReducer,
    timeslots: timeslotsReducer,
    disciplines: disciplinesReducer,
    availabilityPlanner: availabilityPlannerReducer,
    employeesAvailabilities: employeesAvailabilitiesReducer,
    employeeAvailability: employeeAvailabilityReducer,
    roomPlannerPage: roomPlannerPageReducer,
    roomPlanner: roomPlannerReducer,
    roomPlannerDetails: roomPlannerDetailsReducer,
    notifications: notificationsReducer,
    account: accountReducer,
    timeslotTable: timeslotTableReducer,
    adminUser: adminUserReducer,
    events: eventsReducer,
    privateData: privateDataReducer,
    surgeryAssignmentList: surgeryAssignmentReducer,
    opBacklog: opBacklogReducer,
    editOp: opEditLayerReducer,
    print: printReducer
};

export default combineReducers(reducers);
