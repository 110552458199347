// @ts-check
/**
 * @fileoverview redux actions for events
 */

import ActionTypes from "./event_action_types";

/**
 * Set incoming event
 * @param {object} data
 * @param {boolean} data.showBlockscreen
 * @param {string} [data.blockedUntil]
 * @param {string} [data.eventCreatedAt]
 * @param {string} data.eventKey
 * @param {string} [data.eventId]
 * @return {object}
 */
const setEvent = (data) => ({
    type: ActionTypes.SET_EVENT,
    data
});

/**
 * set blocked until
 * @param {string} blockedUntil
 * @return {{type: string, blockedUntil: string}}
 */
function setBlockedUntil(blockedUntil) {
    return {type: ActionTypes.SET_BLOCKED_UNTIL, blockedUntil};
}

/**
 * set showTeaser
 * @param {boolean} showTeaser
 * @return {{type: string, showTeaser: boolean}}
 */
function setShowTeaser(showTeaser) {
    return {type: ActionTypes.SET_SHOW_TEASER, showTeaser};
}

export {setEvent, setBlockedUntil, setShowTeaser};
