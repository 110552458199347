// @ts-check
/**
 * @fileoverview redux reducer utils
 */

/**
 * create reducer
 *
 * @param {Object} initialState
 * @param {Object} handlers
 * @return {Function}
 */
function createReducer(initialState, handlers) {
    return function reducer(state = initialState, action) {
        if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
            return handlers[action.type](state, action);
        }

        return state;
    };
}

export {createReducer};
