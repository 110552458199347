// @ts-check

// import {createMuiTheme} from "@mui/material/styles";
import {createTheme} from "@mui/material/styles";
import merge from "lodash/merge";
import pickBy from "lodash/pickBy";

import createDefaultTheme from "./default_theme";

/**
 * create ui theme
 * @param {Object} settings     customer ui settings
 * @return {Theme}
 */
export default function createCustomTheme(settings) {
    // get all tokens for ui
    const {
        // tokens for material ui
        blackColor = "#000000",
        whiteColor = "#fcfcfc",
        primaryLightColor = "#e0f2f1",
        primaryMainColor = "#00a099",
        primaryDarkColor = "#00706b",
        primaryContrastColor = "#ffffff",
        secondaryLightColor = "#666666",
        secondaryMainColor = "#333333",
        secondaryDarkColor = "#000000",
        secondaryContrastColor = "#ffffff",
        errorLightColor = "#fff5f3",
        errorMainColor = "#be4517",
        errorDarkColor = "#5a0202",
        errorContrastColor = "#ffffff",
        warningLightColor = "#f3d6d6",
        warningMainColor = "#aca116",
        warningDarkColor = "#5a540b",
        warningContrastColor = "#000000",
        infoLightColor = "#fff7cc",
        infoMainColor = "#714d0f",
        infoDarkColor = "#03218a",
        infoContrastColor = "#ffffff",
        successLightColor = "#ebf8f1",
        successMainColor = "#0a9526",
        successDarkColor = "#075d1a",
        successContrastColor = "#ffffff",
        primaryTextColor = "#424242",
        secondaryTextColor = "#616161",
        disabledTextColor = "#999999",
        hintTextColor = "#666666",
        defaultBackgroundColor = "#ffffff",
        paperBackgroundColor = "#fcfcfc",

        // tokens for custom settings
        canvasBackgroundColor = "#f3fcfd",
        layerBackgroundColor = "#fcfcfc",
        emergencyLightColor = "#f2dada",
        emergencyMainColor = "#be4517",
        emergencyDarkColor = "#5a0202",
        emergencyContrastColor = "#ffffff",
        electiveLightColor = "#e0f2f1",
        electiveMainColor = "#00a099",
        electiveDarkColor = "#00706b",
        electiveContrastColor = "#333333",
        activeLightColor = "#e3f6f7",
        activeMainColor = "#7c45ff",
        activeDarkColor = "#7c45ff",
        activeContrastColor = "#ffffff",
        upcomingLightColor = "#ffffff",
        upcomingMainColor = "#dbdbdb",
        upcomingDarkColor = "#dbdbdb",
        upcomingContrastColor = "#333333",
        preparationLightColor = "#cbcba0",
        preparationMainColor = "#d2d60d",
        preparationDarkColor = "#717207",
        preparationContrastColor = "#000000",
        progressLightColor = "#e0f2f1",
        progressMainColor = "#00a099",
        progressDarkColor = "#00a099",
        progressContrastColor = "#333333",
        postLightColor = "#eeeeee",
        postMainColor = "#999999",
        postDarkColor = "#333333",
        postContrastColor = "#ffffff",

        disciplineColors
    } = settings || {};

    // construct mui palette with tokens
    const palette = {
        common: {
            back: blackColor,
            white: whiteColor
        },
        primary: {
            light: primaryLightColor,
            main: primaryMainColor,
            dark: primaryDarkColor,
            contrastText: primaryContrastColor
        },
        secondary: {
            light: secondaryLightColor,
            main: secondaryMainColor,
            dark: secondaryDarkColor,
            contrastText: secondaryContrastColor
        },
        error: {
            light: errorLightColor,
            main: errorMainColor,
            dark: errorDarkColor,
            contrastText: errorContrastColor
        },
        warning: {
            light: warningLightColor,
            main: warningMainColor,
            dark: warningDarkColor,
            contrastText: warningContrastColor
        },
        info: {
            light: infoLightColor,
            main: infoMainColor,
            dark: infoDarkColor,
            contrastText: infoContrastColor
        },
        success: {
            light: successLightColor,
            main: successMainColor,
            dark: successDarkColor,
            contrastText: successContrastColor
        },
        text: {
            primary: primaryTextColor,
            secondary: secondaryTextColor,
            disabled: disabledTextColor,
            hint: hintTextColor
        },
        background: {
            default: defaultBackgroundColor,
            paper: paperBackgroundColor
        }
    };

    // construct custom ui from tokens
    const custom = {
        background: {
            canvas: canvasBackgroundColor,
            layer: layerBackgroundColor
        },
        discipline: disciplineColors,
        emergency: {
            light: emergencyLightColor,
            main: emergencyMainColor,
            dark: emergencyDarkColor,
            contrastText: emergencyContrastColor
        },
        elective: {
            light: electiveLightColor,
            main: electiveMainColor,
            dark: electiveDarkColor,
            contrastText: electiveContrastColor
        },
        active: {
            light: activeLightColor,
            main: activeMainColor,
            dark: activeDarkColor,
            contrastText: activeContrastColor
        },
        upcoming: {
            light: upcomingLightColor,
            main: upcomingMainColor,
            dark: upcomingDarkColor,
            contrastText: upcomingContrastColor
        },
        preparation: {
            light: preparationLightColor,
            main: preparationMainColor,
            dark: preparationDarkColor,
            contrastText: preparationContrastColor
        },
        progress: {
            light: progressLightColor,
            main: progressMainColor,
            dark: progressDarkColor,
            contrastText: progressContrastColor
        },
        post: {
            light: postLightColor,
            main: postMainColor,
            dark: postDarkColor,
            contrastText: postContrastColor
        }
    };

    // merge all palette settings into theme settings
    const theme = merge(createDefaultTheme(palette, custom), pickBy({palette, custom}, Boolean));

    // create mui theme based on settings
    return createTheme(theme);
}
