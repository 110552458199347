/**
 * @fileoverview redux action types events
 */
const ActionTypes = {
    SET_EVENT: "events/SET_EVENT",
    SET_BLOCKED_UNTIL: "events/SET_BLOCKED_UNTIL",
    SET_SHOW_TEASER: "events/SET_SHOW_TEASER"
};

export default ActionTypes;
