// @ts-check
/**
 * Selects the app slice from the  store
 *
 * @param {object} state
 * @return {PrintState}
 */
const selectPrint = (state) => state.print;

const selectPrintDates = (state) => selectPrint(state).printDates;
const selectSavePrintDatesStatus = (state) => selectPrint(state).savePrintDatesStatus;
const selectLoadPrintDatesStatus = (state) => selectPrint(state).loadPrintDatesStatus;

export {selectPrintDates, selectSavePrintDatesStatus, selectLoadPrintDatesStatus};
