// @ts-check
import breakpoints from "./breakpoints";
import spacing from "./spacing";

const HEADER_HEIGHT = "3rem";

/**
 * create the material ui theme
 * @param {Object} palette   the palette settings
 * @param {Object} custom    additional customer settings
 * @return {Object}
 */
const createDefaultTheme = (palette, custom) => ({
    breakpoints: {
        values: breakpoints
    },
    palette: {
        divider: "#e1e1e1",
        border: "#e6e6e6",
        grey: {
            50: "#fcfcfc",
            100: "#eeeeee",
            200: "#e0e0e0",
            300: "#d8d8d8",
            400: "#cecece",
            500: "#999999",
            600: "#757575",
            700: "#616161",
            800: "#424242",
            900: "#333333"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    typography: {
        fontSize: 16
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                // Set the fontSize of the body tag
                // @link https://mui.com/material-ui/react-css-baseline/
                body: {
                    fontSize: "1rem",
                    color: palette.text.primary
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: palette.text.primary
                }
            }
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    "&:first-of-type": {
                        paddingRight: "20px",
                        paddingLeft: "20px"
                    },
                    "position": "relative"
                },
                labelContainer: {
                    "fontSize": "1rem",
                    "&:first-of-type": {
                        "position": "absolute",
                        "width": "280px",
                        "justifyContent": "center",
                        "& .MuiPickersCalendarHeader-switchViewButton": {
                            display: "none"
                        }
                    }
                },
                label: {
                    margin: 0
                }
            }
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                root: {
                    width: "280px"
                },
                spacer: {
                    width: "220px"
                }
            }
        },
        MuiYearPicker: {
            styleOverrides: {
                root: {
                    "& button": {
                        fontSize: "1rem"
                    }
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    minHeight: HEADER_HEIGHT
                },
                colorInherit: {
                    background: palette.common.white
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: HEADER_HEIGHT
                },
                regular: {
                    "minHeight": HEADER_HEIGHT,
                    "@media (min-width: 600px)": {
                        minHeight: HEADER_HEIGHT
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: palette.primary.main,
                    fontSize: "0.75rem",
                    lineHeight: "0.75rem",
                    letterSpacing: "0.4px"
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 10,
                    padding: "0.75rem"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: palette.text.primary,
                    fontSize: "1.125rem",
                    fontWeight: 300,
                    textTransform: "uppercase"
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: "8px 24px"
                }
            }
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: palette.text.secondary,
                    fontSize: "0.75rem",
                    fontWeight: 300
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "1rem 1.25rem",
                    justifyContent: "space-between"
                },
                spacing: {
                    "& > :not(:first-of-type)": {
                        marginLeft: "1rem"
                    }
                }
            }
        },
        MuiMenu: {
            defaultProps: {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "fontSize": "0.875rem",
                    "& MuiMenuItem-selected": {
                        "color": palette.primary.main,
                        "background": "none",
                        "&:hover": {
                            background: palette.primary.light,
                            color: "#333333"
                        }
                    }
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: "unset"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    padding: "0.5rem",
                    borderBottom: "1px solid #e0e0e0"
                },
                head: {
                    fontWeight: 500
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiButtonBase-root": {
                        padding: "0.25rem"
                    },
                    "& .MuiMenuItem-root": {
                        fontSize: "0.875rem"
                    }
                },
                toolbar: {
                    minHeight: "2.25rem"
                },
                caption: {
                    fontSize: "0.875rem"
                },
                input: {
                    fontSize: "0.875rem"
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    whiteSpace: "nowrap"
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "& input": {
                        "&::placeholder": {
                            opacity: 1,
                            color: "#999999"
                        }
                    }
                },
                input: {
                    "fontSize": "0.875rem",
                    "&::placeholder": {
                        opacity: 1,
                        color: "#999999"
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: "0.75rem",
                    height: "1.4rem",
                    backgroundColor: palette.primary.main,
                    marginRight: "0.2rem"
                },
                deleteIcon: {
                    width: "1.2rem",
                    height: "1.2rem"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    whiteSpace: "nowrap"
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: `${palette.primary.main} !important`,
                    width: "4rem",
                    height: "4rem",
                    marginBottom: "1rem"
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    "& svg": {
                        fontSize: "1rem"
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "& svg": {
                        fill: palette.primary.main
                    }
                }
            }
        }
    },
    spacing,
    custom: {
        headerHeight: HEADER_HEIGHT,
        menuWidth: "4rem",
        menuWidthMax: "9.25rem",
        typography: {
            legend: {
                fontSize: "0.688rem"
            },
            footer: {
                fontSize: "0.75rem"
            }
        },
        zIndex: {
            canvas: 100
        },
        topLayerHeight: "11rem",
        popover: {
            width: "616px",
            height: "577px",
            innerScrollableContent: {
                height: "380px"
            }
        },
        verticalBox: {
            width: "150px"
        },
        dayViewCanvas: {
            rowHeight: "106px",
            headerHeight: "47px",
            timesColumnWidth: "68px",
            minColumnWidth: "220px",
            maxColumnWidth: "400px"
        },
        rightPanel: {
            title: {
                textTransform: "uppercase",
                fontSize: "0.875rem",
                display: "flex",
                opacity: 1,
                fontWeight: 700,
                justifyContent: "space-between",
                alignItems: "center",
                height: "80px",
                padding: "0 2.5rem 0 2.5rem",
                color: "#514F4F"
            },
            buttonsWrapperBottom: {
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "80px",
                padding: "0 10px 0 10px"
            },
            button: {
                fontSize: "0.875rem",
                fontWeight: 500,
                padding: `0.25rem 1rem`,
                width: "108px",
                height: "38px"
            },
            divider: {
                width: "100%",
                height: "1px",
                background: "#eeeeee 0% 0% no-repeat padding-box"
            }
        },
        canvasSubTitle: {
            height: "6rem",
            width: "100%",
            padding: "35px 30px",
            fontWeight: 500,
            fontSize: "23px",
            borderBottom: `1px solid #e0e0e0`
        }
    }
});

export default createDefaultTheme;
