/**
 * @fileoverview redux selectors for events
 */

const selectEvents = (state) => state.events;

const selectEventStatus = (state) => selectEvents(state);
const selectShowBlockscreen = (state) => selectEvents(state).showBlockscreen;
const selectEventCreatedAt = (state) => selectEvents(state).eventCreatedAt;
const selectBlockedUntil = (state) => selectEvents(state).blockedUntil;
const selectShowTeaser = (state) => selectEvents(state).showTeaser;
const selectEventKey = (state) => selectEvents(state).eventKey;
const selectEventState = (state) => selectEvents(state).eventState;
const selectEventId = (state) => selectEvents(state).eventId;

export {
    selectEvents,
    selectEventStatus,
    selectShowBlockscreen,
    selectEventCreatedAt,
    selectBlockedUntil,
    selectShowTeaser,
    selectEventKey,
    selectEventId,
    selectEventState
};
