import {composeWithDevTools} from "@redux-devtools/extension";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";

import rootReducer from "../reducers/index";

/**
 * configure store
 * @param {object} preloadedState
 * @return {object} {Store<EmptyObject, AnyAction> & Store<S, A> & {dispatch: ThunkDispatch<{}, undefined, AnyAction>}}
 */
export default function configureStore(preloadedState) {
    const middleware = [thunk];
    const composeEnhancers = composeWithDevTools({});
    const store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(...middleware)));

    return store;
}
