// @ts-check
import {getLocalStorageItem, setLocalStorageItem} from "../../utils/local_storage";
import ActionTypes from "../actions/app_action_types";
import STATUS from "../utils/status";

const initialState = {
    userObject: {},
    status: STATUS.IDLE,
    authError: null, // auth user error
    error: null,
    menuWidth: "4rem", // default 4rem
    showFullMainMenu: false,
    language: getLocalStorageItem("Account", "language") || "de",
    slotViewDays: true, // true: days, false: list
    userStrategy: {},
    refreshTrigger: null, // set date string in order to refetch schedule
    logoutStatus: STATUS.IDLE,
    serviceVersion: null
};

/**
 * Root reducer for the app slice of the store
 *
 * @param {Object} state
 * @param {*} action
 * @return {AppState} state
 */
function rootReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SAVE_USER_OBJECT:
            return Object.assign({}, state, {
                userObject: action.userObject
            });
        case ActionTypes.CHANGE_DATE:
            return Object.assign({}, state, {
                selectedDate: action.payload
            });
        case ActionTypes.AUTH_USER_REQUEST:
            return Object.assign({}, state, {
                status: STATUS.PENDING
            });
        case ActionTypes.AUTH_USER_SUCCESS:
            return Object.assign({}, state, {
                status: STATUS.RESOLVED,
                userObject: action.userObject
            });
        case ActionTypes.AUTH_USER_FAILURE:
            return Object.assign({}, state, {
                status: STATUS.REJECTED,
                authError: action.authError
            });
        case ActionTypes.USER_STRATEGY_REQUEST:
            return Object.assign({}, state, {
                status: STATUS.PENDING
            });
        case ActionTypes.USER_STRATEGY_SUCCESS:
            return Object.assign({}, state, {
                status: STATUS.RESOLVED,
                userStrategy: action.userStrategy
            });
        case ActionTypes.USER_STRATEGY_FAILURE:
            return Object.assign({}, state, {
                status: STATUS.REJECTED,
                error: action.error
            });
        case ActionTypes.TOGGLE_MAIN_MENU:
            return Object.assign({}, state, {
                showFullMainMenu: !state.showFullMainMenu
            });
        case ActionTypes.SET_LANGUAGE:
            setLocalStorageItem("Account", "language", action.language);
            return Object.assign({}, state, {
                language: action.language
            });
        case ActionTypes.TOGGLE_SLOT_VIEW:
            return Object.assign({}, state, {
                slotViewDays: !state.slotViewDays
            });
        case ActionTypes.SET_REFRESH_TRIGGER:
            return Object.assign({}, state, {
                refreshTrigger: new Date().toISOString()
            });
        case ActionTypes.LOGOUT_USER_REQUEST:
            return Object.assign({}, state, {
                logoutStatus: STATUS.PENDING
            });
        case ActionTypes.LOGOUT_USER_SUCCESS:
            return Object.assign({}, state, {
                logoutStatus: STATUS.RESOLVED
            });
        case ActionTypes.LOGOUT_USER_FAILURE:
            return Object.assign({}, state, {
                logoutStatus: STATUS.REJECTED
            });
        case ActionTypes.AUTH_USER_RECOVER:
            return Object.assign({}, state, {
                authError: null
            });
        case ActionTypes.FETCH_SERVICE_VERSION:
            return Object.assign({}, state, {
                serviceVersion: action.version
            });
        default:
    }
    return state;
}
export default rootReducer;
