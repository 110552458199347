// @ts-check
/**
 * @fileoverview API for the app
 */
import axios from "../../middleware/axios.middelware";

const URL_TIMEZONE = "/orchestrator/organization";

/** @typedef {import("axios").AxiosResponse<{data: UserObjectApi, ok: boolean}>} AxiosResponseUserObjectApi */
/** @typedef {import("axios").AxiosResponse<{data: {timezone: string}, ok: boolean}>} AxiosResponseTimezone */
/** @typedef {import("axios").AxiosResponse<{data: UserStrategy, ok: boolean}>} AxiosResponseUserStrategy*/
/** @typedef {import("axios").AxiosResponse} AxiosResponse*/

/**
 * get userObject auth
 *
 * @return {Promise<AxiosResponseUserObjectApi>}
 */
function authUserObject() {
    const URL_AUTH = `${global.IDENTITY_SERVER_AUTHORITY}users/auth/user_object`;
    return axios.get(`${URL_AUTH}?project=nextor`);
}

/**
 * get the organization timezone
 *
 * @param {string} organizationId
 * @return {Promise<AxiosResponseTimezone>}
 */
function getTimezone(organizationId) {
    return axios.get(`${URL_TIMEZONE}/${organizationId}/timezone`);
}

/**
 * Get the user type of auth (Ldap or local). This will set some user permissions.
 *
 * @return {Promise<AxiosResponseUserStrategy>}
 */
const getUserLDAPorLocalStrategy = () => {
    const URL_USER_STRATEGY = `${global.IDENTITY_SERVER_AUTHORITY}user-permissions`;
    return axios.get(URL_USER_STRATEGY);
};

/**
 * Get the user type of auth (Ldap or local). This will set some user permissions.
 *
 * @param {string} refreshToken
 * @return {Promise<AxiosResponse>}
 */
const logoutApi = (refreshToken) => axios.post(`${global.IDENTITY_SERVER_AUTHORITY}logout`, {refreshToken});

/**
 * Get the user type of auth (Ldap or local). This will set some user permissions.
 *
 * @return {Promise<AxiosResponse>}
 */
const fetchServiceVersionApi = () => axios.get("/service-version");

export {authUserObject, getTimezone, getUserLDAPorLocalStrategy, logoutApi, fetchServiceVersionApi};
