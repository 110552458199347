/**
 * @fileOverview spacings from styleguide
 *
 * @see https://app.zeplin.io/project/5e96e7f42e50d97861d4ec7d/styleguide
 */

/**
 * spacings in "rem" unit
 * @see https://app.zeplin.io/project/5e96e7f42e50d97861d4ec7d/styleguide/spacing
 *
 * @type {{xxs: number, s: number, xl: number, xs: number, l: number, m: number, xxl: number}}
 */
const SPACINGS = {
    xxs: 0.125,
    xs: 0.25,
    s: 0.5,
    m: 1,
    l: 2,
    xl: 3,
    xxl: 4
};

/**
 * custom spacing function for the material-ui theme
 *
 * examples:
 *  spacing("s") => 0.5rem
 *  spacing(8.5) => 8.5rem
 *
 * @see https://material-ui.com/customization/spacing/#custom-spacing
 *
 * @param {string|number} value   the spacing name as string or rem value
 * @return {string} the spacing in rem
 */
const spacing = (value) => (isNaN(value) ? `${SPACINGS[value]}rem` : `${value}rem`);

export default spacing;
export {SPACINGS};
